import PageHeader from 'src/components/organisms/PageHeader';
import Footer from 'src/components/molecules/footer/Footer';
import ContentCardsContainer, { CardType, ContentCard } from 'src/components/organisms/ContentCard';
import { CustomDataGrid } from 'src/components/organisms/CustomDataGrid';
import { generateTablePageButtons } from 'src/utils/generateTablePageButtons';
import { useWarehouseShippingMethodsTable } from 'src/hooks/table/configs/useWarehouseShippingMethodsTable';
import DetailPanelContainer from 'src/components/organisms/tables/DetailPanelContainer';
import { usePurchasingShippingMethodsTable } from 'src/hooks/table/configs/usePurchasingShippingMethodsTable';

export  const PurchasingShippingMethods = () => {
    const [columns, tableSettings] = usePurchasingShippingMethodsTable();
    return (
        <>
            <PageHeader  {...{
                title: "Purchasing Shipping Methods",
                subTitle: "Manage shipping methods and shipping codes for shipping from suppliers and manufacturers", 
                components: [
                    ...generateTablePageButtons(tableSettings),
                ]
            }} />
         <ContentCardsContainer >
                <ContentCard {...{ cardTypes: [CardType.noHeader], contentPadding: 0 }}>
                    <CustomDataGrid columns={columns} tableSettings={tableSettings} /> 
                </ContentCard>
            </ContentCardsContainer>


            <Footer />
        </>
    );
}
export default  PurchasingShippingMethods;

