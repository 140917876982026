import { useState, useEffect, useContext, useRef } from 'react';
import _ from 'lodash';
import { extractApiPath, getFromDB, getFromDBandFile, setToDB } from 'src/utils/cache/idbUtility';
import { useSettingsContext } from './useUserPrefs';


function useCache<T>(fullKey: string, externalValue?: T):  [T, (newValue: React.SetStateAction<T | any[]>, replace?: boolean) => void] {
  const key = extractApiPath(fullKey);
  const [state, setState] = useState<T>(externalValue);
  const responseRef = useRef(externalValue);
  const [settings] = useSettingsContext();
  const useFileCache = settings?.UseFileCache ?? false;

  // Load initial value from cache on mount
  useEffect(() => {
    const fetchFromCache = async () => {
      const cachedValue = await getFromDBandFile<T>(key, useFileCache);
      if (cachedValue !== undefined && cachedValue !== null) {
        console.log('useCache: Fetching from cache', key, cachedValue, );
        //if (responseRef.current == undefined || responseRef.current == null || ( Array.isArray(responseRef.current) && responseRef.current.length == 0)) {
          setState(cachedValue);
        //}

      }
    };
    fetchFromCache();
  }, [key]);

  // Update responseData only if externalValue changes
  useEffect(() => {
    if (!_.isEqual(externalValue, responseRef.current)) {
      if (externalValue != undefined || externalValue != null) {
        responseRef.current = externalValue;
        setState(externalValue); // Update state only when externalValue changes
      }
    }
  }, [externalValue]);

  // Update the cache only if state changes and is different from cached value
  useEffect(() => {
    const updateCache = async () => {
      const cachedValue = await getFromDBandFile<T>(key, useFileCache);
      if(responseRef.current != undefined || responseRef.current != null){
        if (!_.isEqual(cachedValue, responseRef.current)) {
          if (responseRef.current != undefined || responseRef.current != null || ( Array.isArray(responseRef.current) && responseRef.current.length > 0)) {
            setToDB(key, responseRef.current);
          }
        }
      }
      else if (!_.isEqual(cachedValue, state)) {
        if (state) {
          setToDB(key, state);
        }
      }
    };
    updateCache();
  }, [key, state, responseRef.current]);

  const customSetState = (newValue: T | any[], replace: boolean = false) => {
    if (replace) {
      // Replace state entirely
      setState(() => (Array.isArray(newValue) ? (newValue as T) : newValue));
    } else {
      // Merge with previous state (default behavior)
      if (Array.isArray(newValue)) {
        setState((prevState) => ([...(prevState as unknown as any[]), ...newValue] as T));
      } else if (typeof newValue === "object" && newValue !== null) {
        setState((prevState) => ({ ...prevState, ...newValue } as T));
      } else {
        setState(newValue);
      }
    }
  };
  

  return [state, customSetState];
}


export default useCache;
