import {  GridColDef } from '@mui/x-data-grid-premium';
import React, { useState } from 'react';
import { tableSettingsProps, useTableSettings } from '../tableSettings/useTableSettings';
import tableHelper from 'src/utils/tableHelper';
import { useBillableOrders, useCreditNotes } from 'src/hooks/services/bold/orders';


export const useBillableOrdersTable = (): [columns: GridColDef[], tableSettings: tableSettingsProps] => {
  const rows = useBillableOrders();
  console.log(rows);
  const idPath = ["CustomerAccount","Value"];
  const columns: GridColDef[] = React.useMemo(
    () => [
      { field: 'actions', getActions: (params) => tableHelper.buttonColumn(params, "Edit", "/warehouse/warehouses/edit?id=", "WarehouseId", "Edit Warehouse"), type: 'actions', headerName: 'Edit', minWidth: 80, maxWidth: 120, flex: 0.8, },
      { field: 'CustomerAccount_Key', headerName: 'Customer Account Id' ,valueGetter: tableHelper.getFieldKeyOrValue, },
      { field: 'CustomerAccount_Value', headerName: 'Customer Account', valueGetter: tableHelper.getFieldKeyOrValue, flex:1 },
      { field: 'SalesOrdersTotalGrossAmount', headerName: 'Sales Orders Total Gross Amount', flex:1 },
      { field: 'BillableTotalGrossAmount', headerName: 'Billable Total Gross Amount', flex:1 },
      { field: 'InvoiceTotalGrossAmount', headerName: 'Invoice Total Gross Amount', flex:1 },
      //{ field: 'BillableOrders', headerName: 'Billable Orders', flex:1,valueGetter: tableHelper.getFieldKeyOrValue, },
      
    ],
    [],
  );
  const tableName = "BillableOrdersTable";
  const tableSettings = useTableSettings({ columns,idPath, rows, tableName });
  
  return [ columns, tableSettings];
};


export const useBillableOrdersRowsTable = (rows = []): [columns: GridColDef[], tableSettings: tableSettingsProps] => {
  const idPath = ["Id"];
  console.log(rows);
  const columns: GridColDef[] = React.useMemo(
    () => [
      
      { field: 'Id', headerName: 'Id' },
      { field: 'OrderNr', headerName: 'OrderNr' },
      { field: 'Status', headerName: 'Status' },
      
      { field: 'CustomerAccount_Value', headerName: 'Customer Account', valueGetter: tableHelper.getFieldKeyOrValue, flex:1, minWidth: 200 },
      { field: 'CustomerOrderReference', headerName: 'Customer Order Reference' },
      { field: 'Currency', headerName: 'Currency' },
      { field: 'ShippingAddressFullName', headerName: 'Shipping Address Full Name' },
      { field: 'SalesType', headerName: 'Sales Type' },
      { field: 'TaxIncluded', headerName: 'Tax Included' },
      { field: 'BillableDiscounts', headerName: 'Billable Discounts', valueGetter: tableHelper.getFieldKeyOrValue, },
      { field: 'BillableCharges', headerName: 'Billable Charges', valueGetter: tableHelper.getFieldKeyOrValue, },
      { field: 'TotalAmountGross', headerName: 'Total Amount Gross' },
      { field: 'TotalChargesGross', headerName: 'Total Charges Gross' },
      { field: 'Discounts', headerName: 'Discounts' },
      { field: 'CreateDate', headerName: 'Create Date' },
      { field: 'TotalBillableAmountGross', headerName: 'Total Billable Amount Gross' },
      { field: 'InvoiceTotalAmountGross', headerName: 'Invoice Total Amount Gross' },
      { field: 'DiscountsNotInvoiced', headerName: 'Discounts Not Invoiced' },
      { field: 'ChargesNotInvoiced', headerName: 'Charges Not Invoiced' },
      { field: 'CustomerAccount_Key', headerName: 'Customer Account Id' ,valueGetter: tableHelper.getFieldKeyOrValue, },
    
    ],
    [],
  );
  
  const tableName = "BillableOrdersRowsTable";
  const tableSettings = useTableSettings({ columns,idPath, rows, tableName });
  
  tableSettings.hasToolbar=false;
  tableSettings.autoHeight=true;
  tableSettings.loading=false;
  tableSettings.hideFooter=true;
  
  return [ columns, tableSettings];
};



export const useBillableItemsRowsTable = (rows = []): [columns: GridColDef[], tableSettings: tableSettingsProps] => {
  const idPath = ["Id"];
  console.log(rows);
  const columns: GridColDef[] = React.useMemo(
    () => [
      
      { field: 'Id', headerName: 'Id' },
      { field: 'BillableOrderId', headerName: 'Billable Order Id' },
      { field: 'SalesItem_Value', headerName: 'Sales Item', valueGetter: tableHelper.getFieldKeyOrValue, flex:1, minWidth: 200 },
      { field: 'Discount', headerName: 'Discount' },
      { field: 'InvoiceId', headerName: 'Invoice Id' },
      { field: 'InvoiceNumber', headerName: 'Invoice Number' },
      { field: 'InvoiceStatus', headerName: 'Invoice Status' },
      { field: 'ItemStatus', headerName: 'Item Status' },
      { field: 'PriceGross', headerName: 'Price Gross' },
      { field: 'Quantity', headerName: 'Quantity' },
      
      { field: 'SalesOrderRowNo', headerName: 'Sales Order Row No' },
    ],
    [],
  );
  
  const tableName = "BillableItemsRowsTable";
  const tableSettings = useTableSettings({ columns,idPath, rows, tableName });
  
  tableSettings.hasToolbar=false;
  tableSettings.autoHeight=true;
  tableSettings.loading=false;
  tableSettings.hideFooter=true;
  
  return [ columns, tableSettings];
};
