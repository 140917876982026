import PageHeader from 'src/components/organisms/PageHeader';
import Footer from 'src/components/molecules/footer/Footer';
import ContentCardsContainer, { CardType, ContentCard } from 'src/components/organisms/ContentCard';
import { CustomDataGrid } from 'src/components/organisms/CustomDataGrid';
import { generateTablePageButtons } from 'src/utils/generateTablePageButtons';
import { useFullfillmentOrdersTable } from 'src/hooks/table/configs/useFullfillmentOrdersTable';
import { useCreditNotesTable } from 'src/hooks/table/configs/useCreditNotesTable';
import { useBillableItemsRowsTable, useBillableOrdersRowsTable, useBillableOrdersTable } from 'src/hooks/table/configs/useBillableOrdersTable';
import { useTransferOrdersRowsTable } from 'src/hooks/table/configs/useTransferOrdersTable';
import DetailPanelContainer from 'src/components/organisms/tables/DetailPanelContainer';

export  const BillableOrders = () => {
    const [columns, tableSettings] = useBillableOrdersTable();
    return (
        <>
            <PageHeader  {...{
                title: "Billable Orders",
                subTitle: "See orders where invoice can be created", 
                components: [
                    ...generateTablePageButtons(tableSettings),
                ]
            }} />
            <ContentCardsContainer >
                <ContentCard {...{ cardTypes: [CardType.noHeader], contentPadding: 0 }}>
                <CustomDataGrid columns={columns} tableSettings={tableSettings} getDetailPanelContent={(row) => <DetailPanelContent {...{ row }} />}/>
                </ContentCard>
            </ContentCardsContainer>


            <Footer />
        </>
    );
}
export default  BillableOrders;


const DetailPanelContent = ({ row }) => {
    const [columns, tableSettings] = useBillableOrdersRowsTable(row?.row?.BillableOrders);
    return (
        <DetailPanelContainer depth={0}>
            <CustomDataGrid columns={columns} tableSettings={tableSettings} getDetailPanelContent={(row) => <SecondDetailPanelContent {...{ row }} />} ></CustomDataGrid>
        </DetailPanelContainer>
    );
}
const SecondDetailPanelContent = ({ row }) => {
    const [columns, tableSettings] = useBillableItemsRowsTable(row?.row?.BillableItems);
    return (
        <DetailPanelContainer depth={1}>
            <CustomDataGrid columns={columns} tableSettings={tableSettings}  ></CustomDataGrid>
        </DetailPanelContainer>
    );
}
