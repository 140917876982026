import { getAllCreditNotes, getAllReturnOrders, getAllSalesOrders, getBillableOrders, getReturnOrderById, getSalesOrdersById } from "src/utils/services/bold/requests";
import { useResponse } from "../useResponse";
import useGetAllPaginatedItems from "../useGetAllPaginatedItems";

export const useOrders = () => {
    return useGetAllPaginatedItems<any>(getAllSalesOrders, 500, "SalesOrderId");
    
}
export const useReturns = () => {
    return useGetAllPaginatedItems<any>(getAllReturnOrders, 1000, "ReturnOrderId");
    
}


export const useOrdersById = (id:string) => {
    const [response] = useResponse<any[]>(getSalesOrdersById,{id}, true);
    return response;
}
export const useReturnsById = (id:string) => {
    const [response] = useResponse<any[]>(getReturnOrderById,{id}, true);
    return response;
}

export const useCreditNotes = () => {
    return useGetAllPaginatedItems<any>(getAllCreditNotes, 1000, "CreditNoteId");
    
}
export const useBillableOrders = () => {
    const [response] = useResponse<any[]>(getBillableOrders);
    return response;
}
