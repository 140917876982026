import { GridColumnMenuProps, GridColumnVisibilityModel, GridGroupingColDefOverride, GridGroupingColDefOverrideParams, GridSortModel, GridPinnedColumns, useGridApiRef, GridRowSelectionModel, GridRowModesModel, GridFilterModel } from '@mui/x-data-grid-premium';
import React, { CSSProperties, JSXElementConstructor, useMemo } from "react";
import { Dispatch, SetStateAction, useState } from "react";
import { NoRows } from "src/components/molecules/table/NoRows";
import { initalFilterProps } from "src/interfaces/tables/tables";
import tableHelper from "src/utils/tableHelper";
import useUpdateEffect from "../../useUpdateEffect";
import { useSettingsContext } from "../../useUserPrefs";
import { getNestedValue } from "src/utils/settings";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { useTableEdits } from "./useTableEdits";

export const defaultCompactDensity = 20;
export const defaultTableDensity = 45;
export const defaultComfortableDensity = 65;
export const defaultGroupingExpansionDepth = 3;

export interface initialTableSettings {
  density?: number;
  allRows?: any,
  addRow?: (newRow: any) => void;
  setDensity?: Dispatch<SetStateAction<number>>;
  groupingColDef?: null | GridGroupingColDefOverride<any> | ((params: GridGroupingColDefOverrideParams) => GridGroupingColDefOverride<any>);
  checkboxSelection?: boolean;
  loading?: boolean;
  setLoading?: Dispatch<SetStateAction<boolean>>;
  idPath?: string[];
  apiRef?: React.MutableRefObject<GridApiPremium>;
  treeData?: boolean;
  noRowsMessage?: JSXElementConstructor<any>;
  initalSorting?: null | GridSortModel;
  hideFooter?: boolean;
  hasToolbar?: boolean;
  hideFooterRowCount?: boolean;
  disableSelectionOnClick?: boolean;
  selectOnlyOneRow?: boolean;
  autoHeight?: boolean;
  customWrapperStyle?: null | CSSProperties;
  hasUnsavedRows?: boolean;
  changedRowsRef?: React.MutableRefObject<any[]>;
  changeCounter?: number;
  originalRowsRef?: React.MutableRefObject<any[]>;
  setHasUnsavedRows?: React.Dispatch<React.SetStateAction<boolean>>;
  updateChangedRowsSetting?: () => void;
  discardAllChanges?: () => void;
  tableName?: string;
  rowModes?: any;
  setRowModes?: (mode: GridRowModesModel) => void;
  discardRowChanges?: (id: any) => void;
  addAndPinMultiEditRow?: () => void;
  removeMultiEditRow?: () => void;
  pinnedRows?: any;
  rowSelectionModel?: GridRowSelectionModel;
  setRowSelectionModel?: React.Dispatch<React.SetStateAction<GridRowSelectionModel>>;
  deleteRow?: (id: any) => void;
  quickFilterValues?: any;
  setQuickFilterValues?: React.Dispatch<React.SetStateAction<any>>;
  filterModel?: GridFilterModel;
  setFilterModel?: React.Dispatch<React.SetStateAction<GridFilterModel>>;
  headerFilter?: boolean;
  setHeaderFilter?: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface tableSettingsProps extends initialTableSettings {
  columnVisibilityModel?: GridColumnVisibilityModel;
  setColumnVisibilityModel?: React.Dispatch<React.SetStateAction<GridColumnVisibilityModel>>;
  initalFilter?:initalFilterProps;
  defaultGroupingExpansionDepth?: number;
  defaultExpanded?: boolean;
  customColumnMenu?: React.ForwardRefExoticComponent<GridColumnMenuProps & React.RefAttributes<HTMLUListElement>>;
  pinnedColumns?: GridPinnedColumns;
  setPinnedColumns?: React.Dispatch<React.SetStateAction<GridPinnedColumns>>;


}



export const useInitialTableSettings = (additionalConfig, rows = []) => {
  const defaultConfig = {
    tableName: "UnnamedTable",
    idPath: ["Id"],
    checkboxSelection: false,
    treeData: false,
    noRowsMessage: "No rows",
    initalSorting: null,
    hideFooter: true,
    hasToolbar: true,
    hideFooterRowCount: true,
    disableSelectionOnClick: false,
    selectOnlyOneRow: false,
    autoHeight: false,
    customWrapperStyle: null,
    setHasUnsavedRows: null,
    updateChangedRowsSetting: null,
    groupingColDef: null,
    originalRowsRef: null,
    changeCounter: null,
  };

  const config = { ...defaultConfig, ...additionalConfig };
  const [loading, setLoading] = useState<boolean>(true);
  const [settings, setSettings] = useSettingsContext();
  const apiRef = useGridApiRef();
  const [rowSelectionModel, setRowSelectionModel] = useState([])
  const updateRowSelectionModel = (newSelectionModel: GridRowSelectionModel) => {
    setRowSelectionModel(newSelectionModel)
  }
  const { allRows, changedRowsRef, hasUnsavedRows, discardAllChanges, discardRowChanges, addRow, rowModes, setRowModes, addAndPinMultiEditRow, removeMultiEditRow, pinnedRows, deleteRow } = useTableEdits(apiRef, rows, config?.tableName, config?.idPath, rowSelectionModel);
  const [density, setDensity] = useState(settings?.TableDensity ?? defaultTableDensity);
  const [quickFilterValues, setQuickFilterValues] = useState("");
  const [filterModel, setFilterModel] = useState<GridFilterModel>(null);

  const headerFilterSetting = getNestedValue(settings, ["HeaderFilter", config?.tableName], false);
  const setHeaderFilter = (newHeaderFilter) => {
    setSettings(newHeaderFilter, "HeaderFilter", config?.tableName ?? "UnNamed")
  }
  
  const tableSettings: initialTableSettings = {
    ...config,
    allRows,
    addRow,
    density,
    setDensity,
    discardAllChanges,
    loading,
    setLoading,
    apiRef,
    hasUnsavedRows,
    changedRowsRef,
    rowModes,
    setRowModes,
    discardRowChanges,
    addAndPinMultiEditRow,
    removeMultiEditRow,
    pinnedRows,
    rowSelectionModel,
    setRowSelectionModel: updateRowSelectionModel,
    deleteRow:deleteRow,
    quickFilterValues,
    setQuickFilterValues,
    setFilterModel,
    filterModel,
    headerFilter:headerFilterSetting,
    setHeaderFilter,
  };
  return tableSettings;
};

export const useCompleteTableSettings = (config, initialTableSettings) => {
  const {
    columns,
  } = config || {};
  const { setLoading, tableName } = initialTableSettings;
  const [pinnedColumns, setPinnedColumns] = React.useState<GridPinnedColumns>();
  const [settings, setSettings] = useSettingsContext();
  
  const tableExpanded = getNestedValue(settings, ["TableExpanded", tableName], false);
  const visibilitySetting = getNestedValue(settings, ["TableColumnVisibility", tableName], tableHelper.getInitalVisibleColumns(columns)) as unknown as GridColumnVisibilityModel;
  const updateColumnVisibilityModel = (newColumnVisibilityModel: GridColumnVisibilityModel) => {
    setSettings(newColumnVisibilityModel, "TableColumnVisibility", tableName ?? "UnNamed")
  }
  useUpdateEffect(() => {
    setLoading(false)
  }, [initialTableSettings.allRows])
  const initalFilter = tableHelper.getDataGridUrlFilter();
  const tableSettings: tableSettingsProps = {
    ...initialTableSettings,
    pinnedColumns: pinnedColumns,
    setPinnedColumns: setPinnedColumns,
    customColumnMenu: null,
    defaultGroupingExpansionDepth: 3,
    defaultExpanded:  tableExpanded,

    columnVisibilityModel: visibilitySetting,
    setColumnVisibilityModel: updateColumnVisibilityModel,
    initalFilter: initalFilter,
    tableName: tableName
  }

  return tableSettings;
};

export const useTableSettings = (config) => {
  const {
    columns,
    rows = [],
    tableName = "UnnamedTable",
    hasToggleMenu = false,
    idPath = ["Id"],
  } = config || {};
  let tableSettings = useInitialTableSettings(config, rows);
  tableSettings = { ...useCompleteTableSettings({ columns }, tableSettings) };

  const completeTableSettings: tableSettingsProps = {
    ...tableSettings,
  }

  return completeTableSettings;
};




const extractIdFromPath = (obj, path) => {
  return path.reduce((acc, key) => acc && acc[key] ? acc[key] : undefined, obj);
};

export const mapObjectsWithId = (arrayOfObjects, idPath) => {
  // Check if the array is empty
  if (!arrayOfObjects?.length) {
    return [];
  }

  return arrayOfObjects.map(obj => {
    const Id = extractIdFromPath(obj, idPath);
    // Check if the id is undefined before adding it to the object
    if (Id !== undefined) {
      return { ...obj, Id };
    } else {
      // Return the object as is if the id path does not exist
      return { ...obj };
    }
  });
};