import {  GridColDef } from '@mui/x-data-grid-premium';
import React, { useState } from 'react';
import { tableSettingsProps, useTableSettings } from '../tableSettings/useTableSettings';
import tableHelper from 'src/utils/tableHelper';
import { useGetFullfillmentOrders,  } from '../../services/bold/warehouse';


export const useFullfillmentOrdersTable = (): [columns: GridColDef[], tableSettings: tableSettingsProps] => {
  const rows = useGetFullfillmentOrders();
  console.log(rows);
  const idPath = ["Id"];
  const columns: GridColDef[] = React.useMemo(
    () => [
      { field: 'actions', getActions: (params) => tableHelper.buttonColumn(params, "Edit", "/warehouse/warehouses/edit?id=", "WarehouseId", "Edit Warehouse"), type: 'actions', headerName: 'Edit', minWidth: 80, maxWidth: 120, flex: 0.8, },
      { field: 'Id', headerName: 'Id', },
      { field: 'Status_Value', headerName: 'Status', valueGetter: tableHelper.getFieldKeyOrValue,renderCell: tableHelper.renderStatusCell, },
      { field: 'CustomerName', headerName: 'Customer Name', width: 220 },
      { field: 'FulfillmentStrategy_Value', headerName: 'Fulfillment Strategy', valueGetter: tableHelper.getFieldKeyOrValue, width: 280 },
      
      { field: 'Priority_Value', headerName: 'Priority', valueGetter: tableHelper.getFieldKeyOrValue, },
      { field: 'SelectedWarehouse', headerName: 'Selected Warehouse', width: 200 },
      { field: 'DateCreated', headerName: 'Date Created', type: 'dateTime', valueGetter: tableHelper.getDateColumn, },
      { field: 'DateFulfilled', headerName: 'Date Fulfilled', type: 'dateTime', valueGetter: tableHelper.getDateColumn, },
      { field: 'DeliveryAddress', headerName: 'Delivery Address', },
      { field: 'DispatchCommands', headerName: 'Dispatch Commands', },
      { field: 'ExternalCustomerId', headerName: 'External Customer Id', },
      { field: 'ExternalCustomerOrderRef', headerName: 'External Customer Order Ref', },
      { field: 'HasDispatchCommandsInStatusCreated', headerName: 'Has Dispatch Commands In Status Created', },
      { field: 'RequiredWarehouse', headerName: 'Required Warehouse', },
      { field: 'RequiresSingleDelivery', headerName: 'Requires Single Delivery', },
      
    ],
    [],
  );
  const tableName = "FullfillmentOrdersTable";
  const tableSettings = useTableSettings({ columns,idPath, rows, tableName });
  
  return [ columns, tableSettings];
};


