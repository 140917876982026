import { set, get, keys,del } from 'idb-keyval';

export const setToDB = async <T>(key: string, val: T): Promise<void> => {
  try {
    await set(key, val);
  } catch (err) {
    console.error('Error setting value to IndexedDB:', err);
  }
};

export const getFromDB = async <T>(key: string): Promise<T | null> => {
  if (!key) {
    console.error('Invalid key provided:', key);
    return null;
  }
  try {
    const cachedValue = await get<T>(key);
    return cachedValue;
  } catch (err) {
    console.error('Error getting value from IndexedDB:', err);
    return null;
  }
};


export const extractApiPath = (url: string) => {
  const apiIndex = url.indexOf('/api/');
  return apiIndex !== -1 ? url.substring(apiIndex) : url;
};

export const getFromDBandFile = async <T>(key: string, useFile:boolean = false): Promise<T | null> => {
  if (!key) {
    console.error('Invalid key provided:', key);
    return null;
  }
  try {
    // Extract the /api/ part from the key
    const apiPathKey = extractApiPath(key);

    // Try getting value from IndexedDB by iterating over keys
    const allKeys = await keys();
    for (const cacheKey of allKeys) {
      const cacheApiPath = extractApiPath(cacheKey.toString());
      if (apiPathKey === cacheApiPath) {
        const value = await get<T>(cacheKey);
        if (value !== undefined && value !== null) {
          return value;
        }
        break;
      }
    }
    if (!useFile) { return null; }
    // Fallback to cache.json
    const response = await fetch('/cache.json');
    if (!response.ok) {
      throw new Error('Failed to fetch cache.json');
    }
    const cache = await response.json();

    // Search in cache.json using the extracted key
    for (const cacheKey in cache) {
      if (cache.hasOwnProperty(cacheKey)) {
        const cacheApiPath = extractApiPath(cacheKey);
        if (apiPathKey === cacheApiPath) {
          // Update IndexedDB cache if found in file
          const valueFromCacheFile = cache[cacheKey];
          await setToDB(cacheKey, valueFromCacheFile); // Use the original cache key
          return valueFromCacheFile;
        }
      }
    }

    return null;
  } catch (err) {
    console.error('Error getting value:', err);
    return null;
  }
};

export const clearDBExceptKey = async (keyToKeep: string): Promise<void> => {
  if (!keyToKeep) {
    console.error('Invalid key to keep:', keyToKeep);
    return;
  }

  try {
    const allKeys = await keys();

    for (const key of allKeys) {
      if (key !== keyToKeep) {
        await del(key); // Directly deletes the key
      }
    }

    console.log(`Cleared all keys except: ${keyToKeep}`);
  } catch (err) {
    console.error('Error clearing IndexedDB keys:', err);
  }
};

export const clearCache = async (): Promise<void> => {
  try {
    // Clear the database cache
    await clearDBExceptKey('/api/GetUserPreferences');
    
    // Clear all cookies
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    }

    console.log('Cache and cookies cleared successfully');
  } catch (err) {
    console.error('Error clearing cache and cookies:', err);
  }
};


/** Remove everything after "?" to ignore query params. */
function extractApiPathNoParams(fullUrl: string): string {
  const [base] = fullUrl.split('?');
  return base;
}

/** Clears any cached entry in IDB whose base path (ignoring query params) matches. */
export async function clearCacheForUrl(fullUrl: string): Promise<void> {
  if (!fullUrl) return;
  const baseToMatch = extractApiPathNoParams(fullUrl);

  try {
    const allKeys = await keys();   // all keys in IDB
    for (const k of allKeys) {
      const keyString = String(k);
      const baseKey = extractApiPathNoParams(keyString);
      if (baseKey === baseToMatch) {
        // They match, so remove from the cache
        await del(k);
      }
    }
  } catch (err) {
    console.error('Error clearing cache:', err);
  }
}
