import PageHeader from 'src/components/organisms/PageHeader';
import Footer from 'src/components/molecules/footer/Footer';
import ContentCardsContainer, { CardType, ContentCard } from 'src/components/organisms/ContentCard';
import { CustomDataGrid } from 'src/components/organisms/CustomDataGrid';
import { generateTablePageButtons } from 'src/utils/generateTablePageButtons';
import { useCurrenciesTable } from 'src/hooks/table/configs/useCurrenciesTable';

export  const Currencies = () => {
    const [columns, tableSettings] = useCurrenciesTable();
    return (
        <>
            <PageHeader  {...{
                title: "Currencies",
                subTitle: "Manage currency exchange rates and settings", 
                components: [
                    ...generateTablePageButtons(tableSettings),
                ]
            }} />
            <ContentCardsContainer >
                <ContentCard {...{ cardTypes: [CardType.noHeader], contentPadding: 0 }}>
                    <CustomDataGrid columns={columns} tableSettings={tableSettings} /> 
                </ContentCard>
            </ContentCardsContainer>


            <Footer />
        </>
    );
}
export default  Currencies;

