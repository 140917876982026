import { getCurrencies, getOrderOrigins, getPurchasingShippingMethods, getPurchasingShippingTerms, getSalesShippingMethods, getTaxRates, getWarehouseShippingMethods } from "src/utils/services/bold/requests";
import { useResponse } from "../useResponse";
import useGetAllPaginatedItems from "../useGetAllPaginatedItems";


export const useCurrencies = () => {
    const [response] = useResponse<any[]>(getCurrencies);
    return response;
}
export const useOrderOrigins = () => {
    const [response] = useResponse<any[]>(getOrderOrigins);
    return response;
}

export const useTaxRates = () => {
    const [response] = useResponse<any[]>(getTaxRates);
    return response;
}
export const useSalesShippingMethods = () => {
    const [response] = useResponse<any[]>(getSalesShippingMethods);
    return response;
}

export const useWarehouseShippingMethods = () => {
    const [response] = useResponse<any[]>(getWarehouseShippingMethods);
    return response;
}
export const usePurchasingShippingMethods = () => {
    const [response] = useResponse<any[]>(getPurchasingShippingMethods);
    return response;
}
export const usePurchasingShippingTerms = () => {
    const [response] = useResponse<any[]>(getPurchasingShippingTerms);
    return response;
}
