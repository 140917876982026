import PageHeader from 'src/components/organisms/PageHeader';
import Footer from 'src/components/molecules/footer/Footer';
import ContentCardsContainer, { CardType, ContentCard } from 'src/components/organisms/ContentCard';
import { CustomDataGrid } from 'src/components/organisms/CustomDataGrid';
import { generateTablePageButtons } from 'src/utils/generateTablePageButtons';
import { useCurrenciesTable } from 'src/hooks/table/configs/useCurrenciesTable';
import { useTaxRatesTable } from 'src/hooks/table/configs/useTaxRatesTable';

export  const TaxRates = () => {
    const [columns, tableSettings] = useTaxRatesTable();
    return (
        <>
            <PageHeader  {...{
                title: "Tax Rates",
                subTitle: "Edit available tax rate codes and values", 
                components: [
                    ...generateTablePageButtons(tableSettings),
                ]
            }} />
            <ContentCardsContainer >
                <ContentCard {...{ cardTypes: [CardType.noHeader], contentPadding: 0 }}>
                    <CustomDataGrid columns={columns} tableSettings={tableSettings} /> 
                </ContentCard>
            </ContentCardsContainer>


            <Footer />
        </>
    );
}
export default  TaxRates;

