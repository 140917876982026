import { useMemo } from "react";
import formHelper from "src/utils/formHelper";
import { useTableCacheForForm } from "./useTableCacheForForm";

export const useFormData = (editId, defaultInitialValues, getItemsFunction, cacheKey, itemKey) => {
    const item = getItemsFunction(editId);
    const matchedItem = useTableCacheForForm(cacheKey, itemKey, editId);

    const initialValues = useMemo(() => {
        // Use `item` if it exists; fallback to `matchedItem` otherwise
        const effectiveItem = item || (item == null && matchedItem ? matchedItem : null);

        let newInitialValues:any = effectiveItem
            ? formHelper.mapToInitialValues(effectiveItem)
            : {};
        newInitialValues.isEditPage = editId != null ? editId : "";

        return editId != null ? newInitialValues : defaultInitialValues;
    }, [item, matchedItem, editId, defaultInitialValues]);

    return initialValues;
};

export default useFormData;
