import PageHeader from 'src/components/organisms/PageHeader';
import Footer from 'src/components/molecules/footer/Footer';
import ContentCardsContainer, { CardType, ContentCard } from 'src/components/organisms/ContentCard';
import { CustomDataGrid } from 'src/components/organisms/CustomDataGrid';
import { generateTablePageButtons } from 'src/utils/generateTablePageButtons';
import { useFullfillmentOrdersTable } from 'src/hooks/table/configs/useFullfillmentOrdersTable';
import { useCreditNotesTable } from 'src/hooks/table/configs/useCreditNotesTable';

export  const CreditNotes = () => {
    const [columns, tableSettings] = useCreditNotesTable();
    return (
        <>
            <PageHeader  {...{
                title: "Credit Notes",
                subTitle: "Register and track credit notes for customer orders", 
                components: [
                    ...generateTablePageButtons(tableSettings),
                ]
            }} />
            <ContentCardsContainer >
                <ContentCard {...{ cardTypes: [CardType.noHeader], contentPadding: 0 }}>
                    <CustomDataGrid columns={columns} tableSettings={tableSettings} /> 
                </ContentCard>
            </ContentCardsContainer>


            <Footer />
        </>
    );
}
export default  CreditNotes;

