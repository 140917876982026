import PageHeader from 'src/components/organisms/PageHeader';
import Footer from 'src/components/molecules/footer/Footer';
import ContentCardsContainer, { CardType, ContentCard } from 'src/components/organisms/ContentCard';
import { CustomDataGrid } from 'src/components/organisms/CustomDataGrid';
import { generateTablePageButtons } from 'src/utils/generateTablePageButtons';
import { useSalesShippingMethodsTable } from 'src/hooks/table/configs/useShippingMethodsTable';

export  const SalesShippingMethods = () => {
    const [columns, tableSettings] = useSalesShippingMethodsTable();
    return (
        <>
            <PageHeader  {...{
                title: "Sales Shipping Methods",
                subTitle: "Manage shipping methods and shipping codes for external systems", 
                components: [
                    ...generateTablePageButtons(tableSettings),
                ]
            }} />
            <ContentCardsContainer >
                <ContentCard {...{ cardTypes: [CardType.noHeader], contentPadding: 0 }}>
                    <CustomDataGrid columns={columns} tableSettings={tableSettings} /> 
                </ContentCard>
            </ContentCardsContainer>


            <Footer />
        </>
    );
}
export default  SalesShippingMethods;

