import {  GridColDef } from '@mui/x-data-grid-premium';
import React, { useState } from 'react';
import { tableSettingsProps, useTableSettings } from '../tableSettings/useTableSettings';
import tableHelper from 'src/utils/tableHelper';
import { useCreditNotes } from 'src/hooks/services/bold/orders';
import { useCurrencies, useSalesShippingMethods, useWarehouseShippingMethods } from 'src/hooks/services/bold/admin';

export const useWarehouseShippingMethodsTable = (): [columns: GridColDef[], tableSettings: tableSettingsProps] => {
  const rows = useWarehouseShippingMethods();
  console.log(rows);
  const idPath = ["Id"];
  const columns: GridColDef[] = React.useMemo(
    () => [
      { field: 'actions', getActions: (params) => tableHelper.buttonColumn(params, "Edit", "/warehouse/warehouses/edit?id=", "WarehouseId", "Edit Warehouse"), type: 'actions', headerName: 'Edit', minWidth: 80, maxWidth: 120, flex: 0.8, },
      { field: 'Id', headerName: 'Id', },
      { field: 'Name', headerName: 'Name', flex:1 },
      { field: 'LogisticsPartner', headerName: 'Logistics Partner', },
      { field: 'LogisticsPartnerCode', headerName: 'Logistics Partner Code', },
      { field: 'LogisticsPartnerServiceCode', headerName: 'Logistics Partner Service Code', },
      { field: 'ShipmentRequired', headerName: 'Shipment Required', },
      { field: 'DimensionRequired', headerName: 'Dimension Required', },
      { field: 'AlwaysPrintReturnLabel', headerName: 'Always Print Return Label', },
    
      
    ],
    [],
  );
  const tableName = "WarehouseShippingMethodsTable";
  const tableSettings = useTableSettings({ columns,idPath, rows, tableName });
  
  return [ columns, tableSettings];
};


