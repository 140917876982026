import { AxiosRequestConfig } from 'axios';
import useRequest from '../services/useRequest';
import useRefMounted from '../useRefMounted';
import { useState, useCallback, useEffect } from 'react';
import { useMessageContext } from 'src/contexts/MessageContext';
import { clearCacheForUrl } from 'src/utils/cache/idbUtility';

interface SubmitOptions {
  setErrors?: (errors: any) => void;
  setStatus?: (status: any) => void;
  setSubmitting?: (isSubmitting: boolean) => void;
}

const DEFAULT_SUBMIT_OPTIONS: SubmitOptions = {
  setErrors: () => {},
  setStatus: () => {},
  setSubmitting: () => {},
};

const useSubmit = <D = any, R = any>(
  buildCurrentRequest: (v: D) => AxiosRequestConfig<any> | AxiosRequestConfig<any>[],
  sequential = false 
): [
    results: R,
    submit: (
      values: D,
      {
        setErrors = () => {}, 
        setStatus = () => {}, 
        setSubmitting = () => {}
      }: {
        setErrors: any;
        setStatus: any;
        setSubmitting: any;
      }
    ) => void
  ] => {
  const isMountedRef = useRefMounted();
  const [results, setRequest] = useRequest<D, R>();
  const [requestQueue, setRequestQueue] = useState<AxiosRequestConfig<any>[]>([]);
  const [currentRequestIndex, setCurrentRequestIndex] = useState(0);
  const {updateMessageStatus} = useMessageContext();

  const processNextRequest = useCallback(() => {
    if (currentRequestIndex < requestQueue.length) {
      setRequest(requestQueue[currentRequestIndex]);
      setCurrentRequestIndex(index => index + 1);
    }
  }, [requestQueue, currentRequestIndex, setRequest]);

  useEffect(() => {
    if (requestQueue.length > 0 && currentRequestIndex == 0) {
      processNextRequest();
    }
  }, [requestQueue]);

  useEffect(() => {
    if (results) {
      // If you want to invalidate the cache for the request that *just* finished:
      const previousIndex = currentRequestIndex - 1;
      if (previousIndex >= 0) {
        const justFinished = requestQueue[previousIndex];
        if (justFinished?.url) {
          // Invalidate the matching GET cache
          clearCacheForUrl(justFinished.url);
        }
      }
      // Now proceed to the next request (if any)
      processNextRequest();
    }
  }, [results, currentRequestIndex, processNextRequest]);

  const submit = (
    values: D,
    { setErrors, setStatus, setSubmitting }: SubmitOptions = DEFAULT_SUBMIT_OPTIONS
  ) => {
    try {
      const requests = buildCurrentRequest(values);
      //Temporary DEMO of Saving with message for boldclient.se
      updateMessageStatus({ requestUrl: (Array.isArray(requests) ? requests[0]?.url : requests?.url), status: 'success', message: 'Saved' })

      console.log(requests);
      if (sequential) {
        setRequestQueue(Array.isArray(requests) ? requests : [requests]);
        setCurrentRequestIndex(0);
      } else {
        if (Array.isArray(requests)) {
          requests.forEach((request) => {
            setRequest(request)
          })
        } else {
          setRequest(requests);
          
        }
      }



      if (isMountedRef()) {
        setStatus({ success: true });
        setSubmitting(false);
      }
    } catch (err) {
      console.error(err);
      if (isMountedRef()) {
        setStatus({ success: false });
        setErrors({ submit: err.message });
        setSubmitting(false);
      }
    }
  }

  return [results, submit];
};

export default useSubmit;

