import { AxiosRequestConfig } from "axios";
import { SelectItem } from "src/interfaces/form/select";
import queryString from 'query-string';
import { useMemo } from "react";

// const mapToInitialValues = (responseData, prefix = "") => {
//   let initalValues = {};

//   if (!responseData)
//     return null;

//   Object.entries(responseData).forEach((entry: [string, any]) => {
//     const [key, value] = entry;
//     if (value?.Key || value?.Id) {
//       const nestedKey = (value?.Key ?? value?.Id) as string | number;
//       initalValues[prefix + key] = (nestedKey);
//       initalValues[prefix + key + "_Value"] = ((value?.Value ?? value?.Name) ?? "") as string | number;
//     }
//     else {

//       initalValues[prefix + key] = (value) ?? "";
//     }
//   });
//   return initalValues;
// }

const mapToInitialValues = (responseData, prefix = "") => {
  let initialValues = {};

  if (!responseData) return null;

  const processNestedObject = (object, objectPrefix) => {
    Object.entries(object).forEach(([nestedKey, nestedValue]) => {
      const fullKey = objectPrefix ? objectPrefix + nestedKey : nestedKey;

      // If the value is null, set it as an empty string
      const value = nestedValue === null ? "" : nestedValue;

      if (value && typeof value === 'object' && !Array.isArray(value)) {
        // Recursive call for deeper nested objects
        processNestedObject(value, fullKey + '_');
      } else if (objectPrefix && (nestedKey === 'Key' || nestedKey === 'Id')) {
        // Use the parent key for Key/Id if not at the base level
        initialValues[objectPrefix.slice(0, -1)] = value;
      } else if (objectPrefix && (nestedKey === 'Value' || nestedKey === 'Name')) {
        // Append '_Value' for Value/Name if not at the base level
        initialValues[objectPrefix + nestedKey] = value;
        initialValues[objectPrefix + "Value"] = value;
      } else {
        // Handle keys normally at the base level
        initialValues[fullKey] = value;
      }
    });
  };

  processNestedObject(responseData, prefix);

  return initialValues;
};


const getEditPageConfig = (formName: string, createFunction: (values: any) => AxiosRequestConfig, updateFunction: (values: any) => AxiosRequestConfig) => {
  const urlSearchParams = useMemo(() => queryString.parse(window.location.search), []);

  const editId = useMemo(() => urlSearchParams?.id ?? null, [urlSearchParams]);
  const productId = urlSearchParams?.productId ?? null;
  const searchParam: any = urlSearchParams?.type ?? productId;
  let PageName = `Create ${formName} `;
  let isEditPage = false;
  let currentSubmitFunction = createFunction;
  if (editId != null) {
    isEditPage = true;
    PageName = `Edit ${formName}  -  ${editId}`;
    currentSubmitFunction = updateFunction;
  }
  return [editId, PageName, currentSubmitFunction, searchParam, isEditPage]
}


const mapToOption = <T>(items: T[], key?: string | number, customValueKey?: string) => {
  let options: SelectItem[] = [];
  if (items != null && items != undefined) {
    if (items.length > 0) {
      items?.forEach(item => {
        if (key == null) {
          options.push({ value: item["Key"], label: item["Value"]?.toString() });
        } else if (customValueKey != null) {
          options.push({ value: item?.[key ?? 0], label: item?.[customValueKey ?? 0]?.toString() });
        }
        else {
          options.push({ value: item?.[key ?? 0]?.Key, label: item?.[key ?? 0]?.Value?.toString() });
        }

      });
    }
  }
  return options;
}

const FormHelper = {
  mapToInitialValues,
  getEditPageConfig,
  mapToOption,
};

export default FormHelper;
