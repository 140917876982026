import { useRoutes } from 'react-router-dom';
import router from './routes';
import { SnackbarProvider } from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import useAuth from 'src/hooks/useAuth';
import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import SuspenseLoader from './components/atoms/SuspenseLoader';
import { MessageProvider } from './contexts/MessageContext';
import { LicenseInfo } from '@mui/x-license-pro';
import { CardProvider } from './contexts/CardContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { EditsProvider } from './contexts/EditsContext';



function App() {
  const content = useRoutes(router);
  const auth = useAuth();
  LicenseInfo.setLicenseKey('002f6e4ddc99c1a694478e8de421fc46Tz0xMDUzNzYsRT0xNzY4MDAzMTk5MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y');

  return (
    <ThemeProvider>
      <SettingsProvider>
        <EditsProvider>
          <MessageProvider>
            <CardProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SnackbarProvider
                  maxSnack={6}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                >
                  <CssBaseline />
                  {auth.isInitialized ? <>
                    {content}

                  </> : <SuspenseLoader />}

                </SnackbarProvider>
              </LocalizationProvider>
            </CardProvider>
          </MessageProvider>
        </EditsProvider>
      </SettingsProvider>
    </ThemeProvider >
  );
}
export default App;

