import {  GridColDef } from '@mui/x-data-grid-premium';
import React, { useState } from 'react';
import { tableSettingsProps, useTableSettings } from '../tableSettings/useTableSettings';
import tableHelper from 'src/utils/tableHelper';
import { useCreditNotes } from 'src/hooks/services/bold/orders';
import { useCurrencies, useTaxRates } from 'src/hooks/services/bold/admin';

export const useTaxRatesTable = (): [columns: GridColDef[], tableSettings: tableSettingsProps] => {
  const rows = useTaxRates();
  console.log(rows);
  const idPath = ["Id"];
  const columns: GridColDef[] = React.useMemo(
    () => [
      { field: 'actions', getActions: (params) => tableHelper.buttonColumn(params, "Edit", "/warehouse/warehouses/edit?id=", "WarehouseId", "Edit Warehouse"), type: 'actions', headerName: 'Edit', minWidth: 80, maxWidth: 120, flex: 0.8, },
      { field: 'Id', headerName: 'Id' },
      { field: 'Name', headerName: 'Name',flex:1 },
      { field: 'TaxRate', headerName: 'Tax Rate', },
      { field: 'IsDefault', headerName: 'Is Default', type: 'boolean', },
      { field: 'CountriesId', headerName: 'Countries Id', },
      { field: 'Country', headerName: 'Country', },
      
      
    ],
    [],
  );
  const tableName = "TaxRatesTable";
  const tableSettings = useTableSettings({ columns,idPath, rows, tableName });
  
  return [ columns, tableSettings];
};


